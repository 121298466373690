<template>
  <div class="bg">
    <img :src="loginbg" alt="" class="bgimg" />
    <div class="login_box">
      <div class="avatarbox">
        <div class="avatarleft">
          <img src="../assets/index.png" alt="" />
        </div>
        <p class="avatartitle">APP<br>后台管理系统</p>
      </div>
      <el-form :model="form" :rules="rules2" ref="form" class="login_form">
        <el-form-item prop="account">
          <el-input prefix-icon="el-icon-user-solid" placeholder="请输入账号" v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="form.pwd" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="login" style="width:80%;">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
      rules2: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      form: {
        account: "",
        pwd: "",
      },
      sid: "",
      loginbg: require("../assets/indexj.jpg"),
    };
  },
  methods: {
    login() {
      this.axios
        .post("http://192.168.11.253:8080/index.php/gu/gu_admin_login", this.qs.stringify(this.form))
        .then((rs) => {
          // console.log(rs)
          if (rs.data.code == 0) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
          } else {
            alert(res);
          }
        });
    },
  },
};
</script>
<style>
.bg {
  position: relative;
  width: 100%;
  height: 100%;
}
.bgimg {
  width: 100%;
  height: auto;
}
.login_bg {
  background: #000;
}

.title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.login_box {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.el-input {
  width: 80%;
}

.el-input__inner {
  width: 100%;
}

.rememberme {
  float: left;
}

.avatarbox {
  height: 100%;
  width: 200px;
  float: left;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 66px;
  box-sizing: border-box;
}
.avatarleft {
  width: 88px;
  height: 88px;
  border: 1px solid #226fef;
  border-radius: 50%;
  padding: 3px;
  margin: 0 auto;
  box-sizing: border-box;
}
.avatartitle {
  color: #226fef;
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  font-family: "楷体";
}
.avatarbox img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.login_form {
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  float: right;
  background-color: #fff;
  padding-top: 72px;
}
.login_form .el-form-item__error {
  left: 40px;
}
</style>
